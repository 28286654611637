.registration .flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.registration .flex .flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.registration .table {
  background: #ffffff;
  padding: 20px 20px 10px 20px;
  border-radius: 10px;
  margin: 15px 0;
}

.registration .table img {
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-right: 15px;
  object-fit: cover;
}

.registration .table .pagination {
  margin-top: 10px;
}

.registration .table .ant-table-wrapper {
  padding: 14px 16px;
}

.registration .table .paginationTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #f1f1f5;
  padding: 24px 24px 14px 24px;
}

.registration .pagination {
  padding-top: 5px;
}