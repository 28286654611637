.view_info {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 24px;
}

.view_info .title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.view_info .title img {
    width: 70px;
    height: 70px;
    margin-top: 2px;
}

.view_info .title h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    margin-left: 20px;
    color: #000000;
}

.view_info .location {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
}

.view_info .location p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin: 0 20px;
    color: #646464;
}

.view_info .date {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.view_info .date h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #0062ff;
}