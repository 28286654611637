.edit__visa form {
    width: 400px;
}

.edit__visa .flex {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}


.edit__visa .btns {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
}