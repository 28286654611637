.national_team .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 22px;
}

.national_team .top h4 {
  font-family: "Poppins";
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #171725;
  letter-spacing: 0.1px;
}

.national_team .top .right {
  display: flex;
  flex-direction: row;
}

.national_team .icon {
  width: 20px;
  height: 20px;
}

.national_team .table img {
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-right: 15px;
  object-fit: cover;
}

.national_team .table {
  background: #ffffff;
  padding: 20px 20px 10px 20px;
  border-radius: 10px;
}

.national_team .table .pagination {
  margin-top: 10px;
}

.national_team .table .ant-table-wrapper {
  padding: 14px 16px;
}

.national_team .table .paginationTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #f1f1f5;
  padding: 24px 24px 14px 24px;
}