.body__list form{
    width: 350px;
}

.body__list .flex {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}


.body__list .btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}