.category {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 24px;
}

.category h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.1px;
    color: #171725;
}

.category h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.1px;
    color: #171725;
}

.category h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #8083a3;
    margin-bottom: 10px;
}

.category input {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #171721;
    text-transform: capitalize;
}