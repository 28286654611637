.flights form {
    width: 500px;
}

.flights .flex {
    display: flex;
    flex-direction: row;
}

.flights .flex .upload {
    margin-right: 28px;
}

.flights .btns {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
}