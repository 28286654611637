.bracketItem .bracketItem-1 {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    margin: 10px 0;
    position: relative;
    height: 50px
}

.bracketItem:nth-child(2) {
    border-top: 0.5px solid rgba(0, 0, 0, 0.2);
}

.bracketItem-1 .close_icon {
    position: absolute;
    top: -5px;
    right: -5px;
    opacity: 0;
}

.bracketItem-1:hover .close_icon {
    opacity: 1;
    transition: 1s;
}

.bracketItem {
    /* background-color: #ffffff;
    padding: 10px;
    border: 1.5px solid rgba(12, 12, 12, 0.3);
    margin: 5px 0; */
    /* box-sizing: border-box;
    border-radius: 10px; */
}

.bracketItem .bracketItem-1 img {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    border-radius: 10px;
}

.bracketItem .bracketItem-1 p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: 0.2px;
    margin-right: 10px;
    color: #171725;
    margin-bottom: 0;
}

.bracketItem .bracketItem-2 {
    display: flex;
    margin-bottom: 0;
}

.bracketItem .bracketItem-2 img {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    margin-bottom: 0;
    border-radius: 10px;
}

.bracketItem .bracketItem-2 p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.2px;
    color: #171725;
    margin-bottom: 0;
}