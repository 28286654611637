.card {
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1px 24px rgba(68, 68, 79, 0.1);
  border-radius: 20px;
  text-align: center;
  padding: 16px;
  margin: 20px auto 20px 0;
}

.card img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-bottom: 8px;
  object-fit: cover;
}

.card h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #171725;
  margin-bottom: 8px;
}

.card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #92929d;
  margin-bottom: 8px;
}

.card .number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #0062ff;
}