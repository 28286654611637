.mainHidden {
  overflow: hidden;
  height: 100vh;
}

.fixed-overlay {
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.fixed-overlay__modal {
  text-align: center;
  white-space: nowrap;
}

.fixed-overlay__modal::after {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
  content: "";
}

.modal {
  display: inline-block;
  vertical-align: middle;
}

.modal_container {
  text-align: left;
  white-space: normal;
  background-color: #fff;
  color: #000;
  border-radius: 16px;
  padding: 20px 30px;
  z-index: 50;
}

.modal_container .popupTop {
  display: flex;
  justify-content: space-between;
}

.modal_container .popupTop h5 {
  width: 350px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 20px;
}
