.date__picker {
    display: flex;
    flex-direction: column;
}

.date__picker label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8083a3;
    margin-bottom: 4px;
}

.date__picker .date__input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e4e6e8;
    padding: 8px 0;
}

.date__picker .date__input.error {
    border-bottom: 1px solid red;
}

.date__picker .date__input input {
    font-family: "Public Sans" !important;
    font-style: normal !important;
    color: #171721 !important;
    background: #fff !important;
    font-weight: 700;
    font-size: 16px;
    outline: none;
    line-height: 24px;
}

.error {
    border-bottom: 1px solid red;
}