.header p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #92929D;
}

.header p span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
}

.user__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.user__info h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
}

.user__info .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user__info .flex img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
    margin-right: 24px;
    object-fit: cover;
}

.user__info .flex h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
}

.user__info .flex .box {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 12px 9.5px;
    margin-right: 6px;
}

.user__info .flex .box p {
    margin-bottom: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #696974;
}

.user__info .flex .box p span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #44444F;
}

.user__info .flex button {
    background: #0062FF;
    box-shadow: 0px 1px 4px rgba(68, 68, 79, 0.1);
    border-radius: 10px;
    padding: 7.75px 12.5px;
    border: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 125px;
    height: 35px;
    border: 1px solid #0062FF
}

.user__info .flex button svg {
    margin-right: 8px;
}