.medical__certificate .flex {
    display: flex;
    flex-direction: row;
}

.medical__certificate .flex .body__list {
    width: 50%;
    padding: 30px 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    margin-right: 20px;
}

.medical__certificate .flex .body__list .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.medical__certificate .flex .body__list .flex h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}

.medical__certificate .flex .body__list .flex button {
    background: #0062FF;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FAFAFB;
    padding: 5px 27px;
    border: 0;
}

.medical__certificate .flex .body__list .flex__s {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.medical__certificate .flex .body__list .flex__s .document {
    width: 230px;
    height: auto;
    background: #FAFAFB;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
}

.medical__certificate .flex .body__list .flex__s .document p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #171721;
}

.medical__certificate .flex .body__list .flex__s .date {
    background: #FAFAFB;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    margin-bottom: 12px;
}

.medical__certificate .flex .body__list .flex__s .date p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #0062FF;
}

.medical__certificate .flex .body__list .flex__s .date h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
}

.medical__certificate .flex .body__list .flex__s .result {
    background: #FAFAFB;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
}

.medical__certificate .flex .body__list .flex__s .result p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #0062FF;
}

.medical__certificate .flex .body__list .flex__s .result h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #3DD598;
}


.medical__certificate .flex .doping__test {
    width: 50%;
    padding: 30px 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.medical__certificate .flex .doping__test .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}


.medical__certificate .flex .doping__test .flex h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}

.medical__certificate .flex .doping__test .flex button {
    background: #0062FF;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FAFAFB;
    padding: 5px 27px;
    border: 0;
}

.medical__certificate .flex .doping__test .flex__s {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.medical__certificate .flex .doping__test .flex__s .document {
    width: 230px;
    height: auto;
    background: #FAFAFB;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
}

.medical__certificate .flex .doping__test .flex__s .document p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #171721;
}

.medical__certificate .flex .doping__test .flex__s .date {
    background: #FAFAFB;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    margin-bottom: 12px;
}

.medical__certificate .flex .doping__test .flex__s .date p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #0062FF;
}

.medical__certificate .flex .doping__test .flex__s .date h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
}

.medical__certificate .flex .doping__test .flex__s .result {
    background: #FAFAFB;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
}

.medical__certificate .flex .doping__test .flex__s .result p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #0062FF;
}

.medical__certificate .flex .doping__test .flex__s .result h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #3DD598;
}

.medical__certificate .vitamins {
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 30px 40px;
    margin-top: 20px;
}

.medical__certificate .vitamins .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.medical__certificate .vitamins .flex h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}

.medical__certificate .vitamins .flex button {
    background: #0062FF;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FAFAFB;
    padding: 5px 19px;
    border: none;
}

.medical__certificate .vitamins .table .tr {
    background: #FAFAFB;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.medical__certificate .vitamins .table .tr:last-child {
    margin-bottom: 0;
}

.medical__certificate .vitamins .table .tr h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #000000;
    margin: 0;
}