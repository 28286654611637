.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 5px 0;
}

.pagination p {
    margin: 0;
}