.main_info {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 24px;
}

.main_info h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: #171725;
}

.main_info .range label{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
  margin-bottom: 4px;
}

.main_info .range .range_picker {
  margin-top: 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e4e6e8;
  outline: none;
  padding: 8px 0;
}

.main_info .range input{
  font-family: "Public Sans";
  font-style: normal;
  color: #171721;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background: #fff;
  outline: none;
}