.video {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 24px;
    margin: 24px 0 40px 0;
}

.video h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.1px;
    color: #171725;
}