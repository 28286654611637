.antd__select {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0px 10px;
    width: 200px;
    height: 42px;
    display: flex;
    /*border: 1px solid #92929D;*/
    align-items: center;
    justify-content: space-between;
    outline: none;
    margin-left: auto;
    z-index: 100;
}

.ant-select-selection-item {
    color: #696974 !important;
    padding: 0 !important;
    margin-right: 10px;
}

.antd__select > * {
    border: none !important;

}

.dropdown_antd {
    margin-left: 10px;
}