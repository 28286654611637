.upload .uploader.error .ant-upload {
    border-left: 1px solid red;
    border-top: 1px solid red;
    border-bottom: 1px solid red;
    border-right: 2px solid red;
}

.upload .uploader .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #171721;
}

.upload .link {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 0;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.upload .link a {
    color: #8083a3;
}