.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
  margin-bottom: 4px;
}

.dropdown_input {
  position: relative;
  background: none;
  width: 100%;
  height: 35px;
  padding: 5px 35px 11px 0;
  border-bottom: 1px solid #e4e6e8;
  margin-top: 6px;
  cursor: pointer;
}

.dropdown_input .title {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #171721;
  text-transform: capitalize;
}

.dropdown_input .icon {
  margin-right: 10px;
  width: 28px;
  height: 100%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.dropdown_input .icon svg {
  width: 20px;
  margin-left: 10px;
  height: 20px;
  margin-top: 5px;
}

.dropdown_input .items {
  overflow: overlay;
  position: absolute;
  border-radius: 50%;
  top: 120%;
  z-index: 15;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 100%;
  left: 0;
  padding: 1px 0;
}

.dropdown_input .items .item {
  padding: 4px 16px 4px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #44444f;
}

.dropdown_input .items .item:hover {
  background: rgba(0, 98, 255, 0.04);
}

.close_back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
