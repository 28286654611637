.searchInput .search {
  padding: 8px;
  text-transform: lowercase;
  border-radius: 10px;
}

.searchInput .search svg {
  width: 15px;
  height: 15px;
  margin-top: -3px;
}

.searchInput .search input {
  font-family: "Poppins";
  font-size: 16px;
}

.searchInput .search input::placeholder {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}

.searchInput .search .ant-input {
  text-transform: capitalize;
}