.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 0 24px;
    margin-bottom: 15px;
}

.card input {
    width: 300px;
    margin: 0 40px 0 0;
}

.card button{
    margin-top: 30px;
}