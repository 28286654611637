.payment .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payment .header h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
}

.payment .header h4 span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #92929d;
}

.payment .header .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.payment .header .create__btn path {
    fill: #fafafb;
}

.payment .header .create__btn svg {
    width: 24px;
    margin-bottom: -6px;
    margin-right: 7px;
}

.payment .table {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    margin: 20px 0;
    padding: 14px 16px;
}

.payment .table thead tr th {
    text-align: center;
    border-bottom: none;
    background: #fafafb;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #44444f;
}

.payment .table tbody tr td {
    text-align: center;
    border-bottom: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #44444f;
}

.payment .table .Detail {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #3dd598;
}

.payment .table .Pay.now {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #fc5a5a;
}


.payment .success {
    color: #3dd598;
    text-transform: capitalize;
}

.payment .paid {
    color: #3dd598;
    text-transform: capitalize;
}

.payment .pending {
    color: #ffa500;
    text-transform: capitalize;
}