.main {
  margin-left: 200px;
  transition: margin-left 0.2s ease-in;
  box-sizing: border-box;
}

.main.inactive {
  margin-left: 80px;
}

.aside {
  position: relative;
  height: 70vh;
  margin: 25px 15px;
  padding-bottom: 10px;
}

.aside-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
