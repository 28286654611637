.edit__admin .flex {
    display: flex;
    flex-direction: row;
}

.edit__admin .date__input {
    border: none;
    border-bottom: 1px solid #e4e6e8;
    outline: none;
    padding: 8px 0;
    margin-top: 10px;
    width: 100%;
    margin-top: 0;
}

.edit__admin .date__input.error {
    border-bottom: 1px solid red;
}

.edit__admin .date__input:focus {
    outline: none;
}

.edit__admin .date__input input {
    line-height: 24px;
    color: #171721;
    font-weight: 700;
    font-size: 16px;
}

.edit__admin label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8083a3;
    margin-bottom: 4px;
}