.member {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);
  border-radius: 20px;
  margin-top: 24px;
}

.member .top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.member .top h4 {
  font-family: "Poppins";
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #171725;
  letter-spacing: 0.1px;
}

.member .top .right {
  display: flex;
  flex-wrap: wrap;
}
.member .top .right > * {
  margin: 10px 10px 10px 0 !important;
}
.member .plusIcon {
  width: 20px;
  height: 20px;
}

.member .table {
  background: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
}

.member .table img {
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-right: 15px;
  object-fit: cover;
}

.member .table .paginationTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #f1f1f5;
  padding: 24px 24px 14px 24px;
}

.svg1 path {
  transition: all ease 0.3s;
  fill: #fff;
}
.btnRed {
  background-color: red;
  transition: all ease-in-out 0.3s;
}
.btnWhite {
  background-color: #fff;
  transition: all ease-in-out 0.3s;
}
