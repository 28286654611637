.club {
    position: relative;
    padding-bottom: 20px;
}

.club .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.club .header h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
}

.club .header .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.club .header .flex .trash {
    font-family: "Poppins";
    font-style: normal;
    background: #ffffff;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 4px 9px 0px 10px;
    border-radius: 10px;
    margin: 0 0 0 20px;
    height: 100%;
}

.club .form {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 16px;
    margin-bottom: 40px;
}

.club .form .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.club .form table img {
    border-radius: 5px;
    width: 42px;
    height: 42px;
    margin-right: 15px;
    object-fit: cover;
}

.club .form table thead tr th {
    background: #fafafb;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #44444f;
}

.club .form .ant-table-thead {
    border-radius: 10px;
}

.club .form table tbody tr td {
    border-bottom: 1px solid #fff;
}

.club .form thead {
    background: #fafafb;
    border-radius: 10px;
}

.club .form thead tr th {
    text-align: center;
    border-bottom: none;
    background: #fafafb;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #44444f;
}

.club .form thead tr th:nth-child(2) {
    text-align: start;
}

.club .form table>thead>tr:first-child th:first-child {
    padding-left: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.club .form table>thead>tr:first-child th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.club .form tbody tr td {
    text-align: center;
    border-bottom: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #44444f;
}

.club .form tbody tr td:nth-child(1) {
    padding-left: 15px;
}

.club .form tbody tr td:nth-child(2) {
    text-align: start;
    font-family: "Poppins", sans-serif;
}