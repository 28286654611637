.competition__national_team .flex {
    display: flex;
}

.competition__national_team .flex button {
    margin-right: 10px;
}

.competition__national_team .flex button:last-child {
    margin-right: 0;
}