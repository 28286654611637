
.information {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 24px;
    margin-top: 24px;
  }
  
  .information .block {
    background: #fafafb;
    border-radius: 8px;
    padding: 16px;
    height: 100%;
  }
  
  .information .block h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #0062ff;
    margin-bottom: 12px;
  }
  
  .information .block .text {
    display: flex;
  }
  
  .information .block .text p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
    margin-right: 10px;
  }
  
  .information .block .text .video a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    color: #92929d;
  }