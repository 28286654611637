.doping__test form{
    width: 350px;
}

.doping__test .flex {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

.doping__test .btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}