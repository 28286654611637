.setting {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 20px;
}

.setting .flex {
  display: flex;
  flex-direction: row;
  align-items: start;
}
