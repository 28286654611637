.member .header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 22px;
  align-items: center;
}

.member .header h4 {
  font-family: "Poppins";
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #171725;
  letter-spacing: 0.1px;
  margin-bottom: 0 !important;
}

.member .header .flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.member .header .flex > * {
  margin: 10px 10px 10px 0 !important;
}

.member .header .flex .trash {
  font-family: "Poppins";
  font-style: normal;
  background: #ffffff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #fff;
  border: none;
  padding: 4px 9px 0px 10px;
  border-radius: 10px;
  margin: 0 0 0 20px;
  height: 100%;
}

.create__btn {
  font-family: "Poppins";
  font-style: normal;
  background: #0062ff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #0062ff;
  padding: 9px 25px 9px 16px;
  border-radius: 10px;
}

.create__btn svg {
  margin-bottom: -7px !important;
  margin-right: 5px;
}
.create__btn svg path {
  fill: #ffffff;
}

.member .actions {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.member .actions button {
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  color: #ffffff;
  line-height: 21px;
  font-weight: 500;
  background: #0062ff;
  border: 1px solid #babaca;
  padding: 9px 25px;
  border-radius: 10px;
  margin: 0 16px 10px 0;
  cursor: default;
}

.member .actions .disactive {
  font-family: "Poppins";
  font-style: normal;
  color: #696974;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  background: #fff;
  border: 1px solid #babaca;
  padding: 9px 25px;
  border-radius: 10px;
  margin: 0 16px 10px 0;
  cursor: default;
}

.member .table {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin: 20px 0;
  padding: 15px 10px;
}

.member .table img {
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-right: 15px;
  object-fit: cover;
}

.member .table .restricted {
  color: #fc5a5a;
}

.member .table .available {
  color: #3dd598;
}

.member .table .ant-table-wrapper {
  padding: 14px 16px;
}

.member .table .pagination {
  border-top: 1px solid #f1f1f5;
  padding: 12px 12px 6px 12px;
}

.svg1 path {
  transition: all ease 0.3s;
  fill: #fff;
}
.btnRed {
  background-color: red;
  transition: all ease-in-out 0.3s;
}
.btnWhite {
  background-color: #fff;
  transition: all ease-in-out 0.3s;
}
