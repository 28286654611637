/* /// Competitions Table /// */

.table {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
}

.table .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #000000;
  width: 80px;
}

.table img {
  width: 42px;
  height: 42px;
}

.table .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
  margin: 0;
}

.table .people {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #646464;
  margin: 0;
}

.table .city {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #646464;
  width: 100px;
  margin-bottom: 0px;
}
