.payment {
    position: "relative";
    padding-bottom: "20px"
}

.payment .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.payment .top h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
}

.payment .table {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 16px;
    margin-bottom: 40px;
}

.payment .table img {
    border-radius: 5px;
    width: 42px;
    height: 42px;
    margin-right: 15px;
    object-fit: cover;
}

.payment .table thead tr th {
    background: #fafafb;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #44444f;
}

.payment .table .ant-table-thead {
    border-radius: 10px;
}

.payment .table table tbody tr td {
    border-bottom: 1px solid #fff;
}

.payment .table .info__btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.payment .table .info__btn p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.1px;
    color: rgba(68, 68, 79, 0.5);
}

.payment .table .info__btn p span {
    color: #44444f;
    margin: 0 20px 0 5px;
}

.payment .table .info__btn .flex {
    display: flex;
    flex-direction: row;
}

.payment .table thead {
    background: #fafafb;
    border-radius: 10px;
}

.payment .table thead tr th {
    text-align: center;
    border-bottom: none;
    background: #fafafb;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #44444f;
}

.payment .table thead tr th:nth-child(2) {
    text-align: start;
}

.payment .table table>thead>tr:first-child th:first-child {
    padding-left: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.payment .table table>thead>tr:first-child th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.payment .table tbody tr td:hover {
    background: none;
}

.payment .table tbody tr td {
    text-align: center;
    border-bottom: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #44444f;
}

.payment .table tbody tr td:nth-child(1) {
    padding-left: 15px;
}

.payment .table tbody tr td:nth-child(2) {
    text-align: start;
    font-family: "Poppins", sans-serif;
}

.payment .pagination_table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 15px;
}