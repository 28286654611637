.information_last {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 24px;
  margin-top: 24px;
}

.information_last .block {
  background: #fafafb;
  border-radius: 8px;
  padding: 16px;
  height: 100%;
}

.information_last .block h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #0062ff;
  margin-bottom: 12px;
}

.information_last .block .quotas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.information_last .block .quotas h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #92929d;
  text-transform: capitalize;
}

.information_last .block .quotas p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.information_last .block .quotas .regions {
  padding-right: 8px;
}

.information_last .block .quotas .male {
  border-left: 1px solid #babaca;
  border-right: 1px solid #babaca;
  padding: 0 16px;
  text-align: center;
}

.information_last .block .quotas .female {
  text-align: center;
  padding: 0 16px;
  border-right: 1px solid #babaca;
}

.information_last .block .quotas .quotaGrand {
  display: flex;
}

.information_last .points {
  display: flex;
}

.information_last .points .left {
  margin-right: 20px;
}

.information_last .points .right {
  width: 200px;
}

.information_last .points .right .images {
  width: 100%;
  display: flex;
}

.information_last .points .right .images img {
  margin: 0 auto;
}

.information_last .points .right .count {
  margin-top: 6px;
  display: flex;
}

.information_last .points .right .count p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #000000;
  margin: 0 auto;
  padding-right: 4px;
}
