.login {
  background: url(../../assets/images/backgroundLogin.png) no-repeat;
  background-size: cover;
  background-position: top;
  height: 100vh;
  padding: 20px;
  position: relative;
}

.login::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(17, 16, 94, 0.50);
  width: 100%;
  height: 100%;
  z-index: 5;
}

.login {
  position: relative;
  z-index: 10;
}

.login .login__logo {
  position: absolute;
  background: #fff;
  width: 154px;
  border-radius: 21px;
  padding: 16px 16px 8px 16px;
  z-index: 10;
}

.login .login__logo img {
  width: 120px;
  height: auto;
}

.login h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.233333px;
  color: #ffffff;
  margin-top: 50px;
  position: relative;
  z-index: 10;
}

.login .login__form {
  width: 500px;
  background: #ffffff;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  z-index: 2;
  padding: 39px 50px;
  text-align: center;
  margin: 10vh auto 0 auto;
  position: relative;
  z-index: 10;
}

.login .login__form h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #11105e;
}