.payment h4 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #171721;
}

.payment .btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}
