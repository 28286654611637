.date_detail {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);
    border-radius: 20px;
    padding: 24px;
    margin-top: 20px;
}

.date_detail .date {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.date_detail .date h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #0062ff;
    margin-bottom: 0;
}

.date_detail .location {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e1e1e1;
}

.date_detail .location p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin: 0 20px;
    color: #646464;
}