.admins .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 22px;
}

.admins .top h4 {
    font-family: "Poppins";
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #171725;
    letter-spacing: 0.1px;
}

.admins .top .right {
    display: flex;
    flex-direction: row;
}

.admins .top .right .plus__icon {
    width: 24px !important;
    margin-bottom: -6px !important;
    margin-right: 6px !important;
}

.admins .top .right .plus__icon path {
    fill: #fafafb !important;
}


.admins .flex {
    display: flex;
    margin-bottom: 10px;
}