.select__box {
    position: "relative"
}

.select__box .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8083a3;
    margin-bottom: 4px;
}

.select__box .select {
    width: 100%;
    height: 38px;
    outline: none;
}