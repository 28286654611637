.input_field {
  margin: 20px 0;
  text-align: left;
}

.input_field label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
  margin-bottom: 4px;
}

.input_field input {
  font-family: "Public Sans";
  font-style: normal;
  color: #171721;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e4e6e8;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background: #fff;
  outline: none;
  padding: 8px 0;
}

.input_field input::placeholder {
  color: #171721;
  opacity: 1;
}

/* .input_field input.error {
  font-family: "Public Sans";
  font-style: normal;
  color: #171721;
  width: 100%;
  border: none;
  border-bottom: 1px solid red;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background: #fff;
  outline: none;
  padding: 8px 0;
}

.ant-select-dropdown {
  border-radius: 10px;
} */
