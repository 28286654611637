.loader {
  width: 250px;
  height: 250px;
  fill: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader path {
  fill: none;
}
