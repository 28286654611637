.vise__datas .visa__list {
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
    padding: 30px 40px;
    margin-top: 40px;
}

.vise__datas .visa__list .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.vise__datas .visa__list .flex h4 {
    color: #000;
    font-size: 24px;
    font-family: "Poppins";
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
}

.vise__datas .visa__list .flex button {
    color: #FAFAFB;
    text-align: center;
    font-size: 14px;
    font-family: "Poppins";
    line-height: 21px;
    border-radius: 8px;
    background: #0062FF;
    padding: 5px 10px;
    border: none;
}

/* ////////////////// flights //////////// */

.vise__datas .flights {
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
    padding: 30px 40px;
    margin-top: 40px;
}

.vise__datas .flights .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.vise__datas .flights .flex h4 {
    color: #000;
    font-size: 24px;
    font-family: "Poppins";
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
}

.vise__datas .flights .flex button {
    color: #FAFAFB;
    text-align: center;
    font-size: 14px;
    font-family: "Poppins";
    line-height: 21px;
    border-radius: 8px;
    background: #0062FF;
    padding: 5px 10px;
    border: none;
}

.vise__datas .flights .table .tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background: #FAFAFB;
    padding: 15px 16px;
    margin-bottom: 6px;
}

.vise__datas .flights .table .tr:last-child {
    margin-bottom: 0;
}

.vise__datas .flights .table .tr h5 {
    color: #000;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    letter-spacing: 0.1px;
    width: 100px;
}

.vise__datas .flights .table .tr h5:first-child {
    width: 200px;
}