.contribution .header {
    display: flex;
    flex-direction: row;
}

.contribution .header h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
}