.header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #e2e2ea;
}

.header .header__end {
  margin-left: auto;
  margin-right: 20px;
}

.header .header__end button {
  border: none;
  background: #fff;
  display: flex;
  align-items: center;
}

.header .header__end .header__profile__view {
  position: relative;
}

.header .header__end .header__profile__view img {
  height: 36px;
  width: 36px;
  background-color: #f1f5f7;
  padding: 3px;
  border-radius: 20px;
}

.header .header__end .header__profile__view .user__view {
  margin: 0 0 0 10px;
  text-align: left;
  padding-top: 10px;
}

.header .header__end .header__profile__view .user__view h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #171725;
  text-transform: capitalize;
}

.header .header__end .header__profile__view .user__view p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1px;
  color: #92929d;
  text-transform: capitalize;
}
