.ranking .header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.ranking .header h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #171725;
}

.ranking .header .flex {
  display: flex;
  flex-wrap: wrap;
}
.ranking .header .flex > * {
  margin: 10px 10px 10px 0 !important;
}
.ranking .table {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 10px;
  margin-top: 20px;
}

.ranking .table table {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
}

.ranking .table table thead tr th {
  text-align: center;
  background: #fafafb;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #44444f;
}

.ranking .table table thead tr th:nth-child(2) {
  text-align: start;
}

.ranking .table table thead tr th:nth-child(7) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ranking .table table tbody tr td {
  text-align: center;
  background: #ffffff;
  border-bottom: none;
}

.ranking .table table tbody tr td:nth-child(2) {
  text-align: start;
}

.ranking .table table tbody tr td:nth-child(7) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
}

.ranking .table table img {
  width: 42px;
  height: 42px;
  margin-right: 15px;
}

.ranking .table table .Detail {
  color: #3dd598;
}

.ranking .table table .pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #f1f1f5;
  padding: 16px;
}

.ranking .table table .pagination p {
  margin: auto 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #44444f;
}
