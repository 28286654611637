.prof__modal .ant-radio-inner {
    width: 24px;
    height: 24px;
    top: 2px;
}

.prof__modal .ant-radio-inner::after {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
}

.prof__modal span {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8083a3;
}