@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

* {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

body {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  background-color: #fafafb;
  font-family: "Poppins", sans-serif;
  /* font-family: "Public Sans", sans-serif; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Inter, sans-serif;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

a {
  text-decoration: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.table {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin: 20px 0;
}

.table thead {
  background: #fafafb;
  border-radius: 10px;
}

.table thead tr th {
  text-align: center;
  border-bottom: none;
  background: #fafafb;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #44444f;
}

.table thead tr th:nth-child(2) {
  text-align: start;
}

.table table > thead > tr:first-child th:first-child {
  padding-left: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table table > thead > tr:first-child th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}

.table tbody tr td:hover {
  background: none;
}

.table tbody tr td {
  text-align: center;
  border-bottom: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #44444f;
}

.table tbody tr td:nth-child(1) {
  padding-left: 15px;
}

.table tbody tr td:nth-child(2) {
  text-align: start;
  font-family: "Poppins", sans-serif;
}

.Available {
  color: #3dd598;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #3dd598;
  border-color: #3dd598;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: #3dd598;
}

/* .ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
} */
.ant-select-selection-search {
  left: 0 !important;
}
.ant-table-row-selected:active {
  background-color: none;
}

.time-table-row-select
  > .ant-spin-nested-loading
  > .ant-spin-container
  > div
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > tbody
  > tr:hover
  > td {
  background-color: unset;
}
.row {
  cursor: pointer;
}
* {
  scrollbar-width: none;
  scrollbar-color: #0062ff #ffffff;
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #0062ff;
  border-radius: 14px;
  border: 4px solid #ffffff;
}

.activeLinkBtn {
  background: #0062ff;
  color: #fff;
}

.inactiveBtn {
  border: 1px solid #babaca;
  background: #fff;
  color: #0062ff;
}

.textUploadFile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #171721;
}

.deleted {
  color: #fc5a5a;
}

.restricted {
  color: #fc5a5a;
}

.available {
  color: #3dd598;
}

.avatarUploader .ant-upload {
  width: 155px;
  height: 155px;
}

.avatarUploader.error .ant-upload {
  border-left: 1px solid red;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-right: 2px solid red;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: none;
  background: none;
  cursor: auto;
}

.ant-picker.ant-picker-disabled {
  color: none;
  background: none;
  cursor: auto;
}

.ant-picker-input > input[disabled] {
  color: #171721;
  cursor: auto;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: auto;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0062ff;
  border-color: #0062ff;
}

/* ///// Select ////////// */

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid #e4e6e8;
}

.ant-input:focus {
  border: 1px solid #fff;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  color: #171721;
  text-transform: capitalize;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
  height: 38px;
}

.ant-select-arrow {
  right: 5px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
}

.upload .ant-upload {
  width: 165px;
  height: 166px;
  border-radius: 24px;
  border: none;
  background: #eee;
}

.upload .error .ant-upload {
  border-left: 1px solid red;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-right: 2px solid red;
}
.ant-col-6 {
  max-width: 27%;
  flex: 0 0 30%;
}
