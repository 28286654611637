.mainHidden {
    overflow: hidden;
    height: 100vh;
}

.fixed__overlay {
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
}

.fixed__overlay__modal {
    text-align: center;
    white-space: nowrap;
}

.fixed__overlay__modal::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: "";
}

.fixed__overlay .modal {
    display: inline-block;
    vertical-align: middle;
}

.fixed__overlay .modal .container {
    text-align: left;
    white-space: normal;
    background-color: #fff;
    color: #000;
    border-radius: 16px;
    padding: 20px 30px;
    z-index: 50;
}

.fixed__overlay .modal .container .header {
    display: flex;
    justify-content: space-between;
}

.fixed__overlay .modal .container .header h5 {
    width: 350px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 20px;
}