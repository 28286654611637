.csearch label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
  margin-bottom: 4px;
}

.csearch .error {
  border-bottom: 1px solid red;
}

.csearch .select {
  width: 100%;
  height: 38px;
  outline: none;
}

.csearch .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid #e4e6e8;
}

.csearch .ant-input:focus {
  border: 1px solid #fff;
}

.csearch
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0;
  height: 38px;
}

.csearch .ant-select-arrow {
  right: 5px;
}

.csearch .ant-select-focused .ant-select-selector,
.csearch .ant-select-selector:focus,
.csearch .ant-select-selector:active,
.csearch .ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.csearch .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  margin-left: -10px;
}

.csearch
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  color: #171721;
}
