.competition .top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.competition .top h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #171725;
}

.competition .top .flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.competition .top .flex > * {
  margin: 10px 10px 10px 0 !important;
}
.competition .top .btn svg {
  transition: all ease 0.3s;
  width: 20px;
  height: 20px;
  margin-bottom: -3px;
  margin-right: 8px;
}
.competition .top .btn {
  margin: 0 !important;
  transition: all ease 0.3s;
}

.competition .top .btn svg path {
  transition: all ease 0.3s;

  fill: #ffffff;
}

.competition .btns h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #171725;
  margin: 0;
}

.competition .btns button svg {
  width: 18px;
  margin-bottom: -6px;
  margin-right: 3px;
}

.competition .btns .historyBtn svg {
  width: 22px;
  margin-bottom: -6px;
  margin-right: 0;
}

.competition .btns .iconWhite path {
  fill: #fff;
}

.competition .btns .iconGrey path {
  fill: #696974;
}

.competition .competitions__list {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin: 24px 0;
}
.svg1 path {
  transition: all ease 0.3s;
  fill: #fff;
}
.btnRed {
  background-color: red;
  transition: all ease-in-out 0.3s;
}
.btnWhite {
  background-color: #fff;
  transition: all ease-in-out 0.3s;
}
