.tournament-container {
    overflow: scroll;
    padding: 20px 0;
}

.tournament-headers {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
}

.tournament-headers h3 {
    width: 25%;
    text-align: center;
    font-weight: 400;
    border-right: 1px dashed #ccc;
    margin: 0;
    padding: 1rem;
}

.tournament-brackets {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin-bottom: 50px;
}

.bracket {
    padding-left: 0;
    display: flex;
    margin: 0;
    padding: 30px 0;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-around;
    list-style-type: none;
    flex: 1;
}

.bracket.isOver .team-item .bracketItem {
    background-color: #abb3c2;
}

.team-item {
    background: #fafafb;
    border-radius: 10px;
    padding: 6px 12px;
    display: block;
    margin: 0.5rem 10px;
    position: relative;
    vertical-align: middle;
    line-height: 2;
    text-align: center;
    cursor: pointer;
}

.team-item:after {
    content: "";
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 2px;
    position: absolute;
    display: block;
    width: 10px;
    right: -11px;
}

.team-item:nth-of-type(odd):after {
    border-right-style: solid;
    border-top-style: solid;
    height: 95%;
    top: 49%;
}


.team-item:nth-of-type(even):after {
    border-right-style: solid;
    border-bottom-style: solid;
    height: 19%;
    top: 32%;
}

.robin:after, .robin:before {
    border-right-style: none !important;
}


.team-item:before {
    content: "";
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    height: 2px;
    width: 10px;
    left: -10px;
    top: 50%;
}


.bracket-2 .team-item:nth-of-type(odd):after {
    height: 140%;
    top: 49%;
}

.bracket-2 .team-item:nth-of-type(even):after {
    height: 82%;
    top: -32%;
}

.bracket-3 .team-item:nth-of-type(odd):after {
    height: 350%;
    top: 50%;
}

.bracket-3 .team-item:nth-of-type(even):after {
    height: 350%;
    top: -300%;
}

.bracket-4 .team-item:nth-of-type(odd):after {
    height: 700%;
    top: 50%;
}

.bracket-4 .team-item:nth-of-type(even):after {
    height: 700%;
    top: -650%;
}

.bracket:first-of-type .team-item:before {
    display: none;
}

.bracket-4 .team-item:after {
    display: none;
}


.bracket-6 .team-item:after {
    content: "";
    /* border-color: #4f7a38; */
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    border-width: 2px;
    position: absolute;
    display: block;
    width: 10px;
    height: 90px;
    top: 32px;
    right: -11px;
    /*display: none !important;*/
}

.bracket-7 .team-item:before {
    display: block !important;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    height: 50px;
    width: 10px;
    left: -10px;
    top: 50%;
}

.bracket:last-of-type .team-item:before,
.bracket:last-of-type .team-item:after {
    display: none;
}

.team-item time {
    display: inline-block;
    background-color: #dbdbdb;
    font-size: 0.8rem;
    padding: 0 0.6rem;
}