.competitionDetail .competitionsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.competitionDetail .competitionsHeader h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
}

.competitionDetail .competitionsBtn h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
    margin: 0;
}

.competitionDetail .competitionsBtn button svg {
    width: 18px;
    margin-bottom: -6px;
    margin-right: 3px;
}

.competitionsBtn {
    display: flex;
    align-items: center;
}

.competitionDetail .competitionsBtn .historyBtn svg {
    width: 22px;
    margin-bottom: -6px;
    margin-right: 0;
}

.competitionDetail .competitionsBtn .iconWhite path {
    fill: #fff;
}

.competitionDetail .competitionsBtn .iconGrey path {
    fill: #696974;
}

.competitionDetail .competitionsBorder {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    margin: 24px 0;
}


.competitionsBorder .weight_tabs.active {
    border-bottom: 3px solid #1d39c4;
}

.competitionsBorder .weight_tabs {
    text-align: center;
}


.competitionDetail .paginationTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #f1f1f5;
    padding: 24px 24px 0 24px;
}

.competitionDetail .ant-pagination svg {
    margin-bottom: 3px;
}

.competitionDetail .ant-pagination .ant-pagination-item {
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;
}

.competitionDetail .ant-pagination .ant-pagination-prev {
    border: 1px solid #e2e2ea;
    border-radius: 8px;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.competitionDetail .ant-pagination .ant-pagination-prev button {
    border: 1px solid #e2e2ea;
    border-radius: 8px;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.competitionDetail .ant-pagination .ant-pagination-next {
    border: 1px solid #e2e2ea;
    border-radius: 8px;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.competitionDetail .ant-pagination .ant-pagination-next button {
    border: 1px solid #e2e2ea;
    border-radius: 8px;
    transform: matrix(1, 0, 0, -1, 0, 0);
}
