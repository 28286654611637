.bracket__table {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.bracket__table ul {
    width: 20   0px;
    border: 1px solid #d9d9d9;
    padding: 20px;
}

.bracket__table ul h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
}

.bracket__table ul li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bracket__table ul li .number {
    margin-right: 5px;
}

.bracket__table ul li p{
    margin: 0;
    color: #0062ff;
    margin-left: 10px;
}