.trophies .table {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    margin-top: 32px;
}

.trophies .table .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
}

.trophies .table .flex h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}

.trophies .table .flex button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FAFAFB;
    background: #0062FF;
    border-radius: 8px;
    padding: 5px 9px;
    border: none;
}