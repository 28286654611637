.national_team__detail .details {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    padding: 12px 0 12px 24px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.national_team__detail .details .document {
    background: #FAFAFB;
    border-radius: 8px;
    width: 202px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 12px 24px 12px 0;
}

.national_team__detail .details .document p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #171721;
}

.national_team__detail .details .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.national_team__detail .details .flex .box {
    background: #FAFAFB;
    border-radius: 8px;
    width: 47%;
    margin: 12px 24px 12px 0;
    padding: 16px;
}

.national_team__detail .details .flex .box h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #0062FF;
}

.national_team__detail .details .flex .box p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
}

.national_team__detail .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 32px;
}

.national_team__detail .cards .card {
    width: 49%;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    margin-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 27px 24px;
    margin-bottom: 26px;
}

.national_team__detail .cards .card:nth-of-type(2n) {
    margin-right: 0;
}

.national_team__detail .cards .card p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #171725;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
}

.national_team__detail .cards .card p svg {
    margin-right: 12px;
}

.national_team__detail .cards .card button {
    border: 1px solid #0062FF;
    border-radius: 34px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 3px 26px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #0062FF;
    background: 0;
}

.national_team__detail .cards .card button:hover{
    background: #0062FF;
    color: #ffff;
}