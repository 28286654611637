.closeBackDropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
}

.dropdown {
  position: relative;
  background: #ffffff;
  width: 215px;
  border-radius: 10px;
  padding: 13px 35px 11px 12px;
  z-index: 15;
}

.dropdown .dropdownTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #696974;
}

.dropdown .dropdownTitle span {
  width: 28px;
  text-transform: capitalize;
  font-weight: 500;
  color: #44444f;
  margin-left: 3px;
}

.dropdown .dropdownIcon {
  margin-right: 10px;
  width: 28px;
  height: 100%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #f1f1f5;
}

.dropdown .dropdownIcon svg {
  width: 20px;
  margin-left: 8px;
  height: 20px;
  margin-top: 10px;
}

.dropdown .dropdownItems {
  position: absolute;
  border-radius: 50%;
  top: 120%;
  z-index: 6;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 100%;
  left: 0;
  padding: 8px 0;
}

.dropdown .dropdownItems .dropdownItem {
  padding: 4px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #44444f;
}

.dropdown .dropdownItems .dropdownItem:hover {
  background: rgba(0, 98, 255, 0.04);
}

.dropdown .placeholder {
  position: absolute;
  top: 30%;
  left: 32%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #92929d;
  text-transform: capitalize;
  z-index: -1;
}
