.sideBarMenu {
  position: fixed;
  background: #ffffff;
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  transition: width 0.2s ease-in;
}

.sideBarMenu .sideBarTopSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sideBarMenu .sideBarTopSection .sidebarLogo {
  overflow: hidden;
  display: flex;
  align-content: center;
}

.sideBarMenu .sideBarTopSection .sidebarLogo img {
  width: 130px;
  height: 100px;
}

.sideBarMenu .sidebarMenu .menuItem {
  padding: 2px 0;
  text-decoration: none;
  font-size: 15px;
  display: block;
  font-weight: 600;
  cursor: pointer;
  color: #d7e4ec;
  margin: 5px 6px;
}

.sideBarMenu {
  overflow-y: scroll;
  overflow-x: hidden;
}

.sideBarMenu a {
  display: block;
  width: 100%;
}

.sideBarMenu .menuItem.active {
  border-left: 3px solid #0062ff;
}

.sideBarMenu .menuItem.active span {
  color: #0062ff;
}

.sideBarMenu .menuItem span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #171725;
}

.sideBarMenu .menuItem .menuIcon {
  display: inline-block;
  transition: all 0.4s;
  font-family: Inter, sans-serif;
  font-weight: 500;
  min-width: 1.5rem;
  padding-bottom: 0.125em;
  font-size: 1.4rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #505d69;
  -webkit-transition: all 0.4s;
  opacity: 0.75;
  margin-top: 6px;
  margin-right: 6px;
  margin-left: 10px;
}

.sideBarMenu .menuItem.active svg path {
  fill: #0062ff;
}

.sideBarMenu .menuItem.active svg path path {
  fill: #0062ff;
}
