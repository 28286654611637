.not_found {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.not_found h1 {
    font-size: 100px;
    font-weight: 700;
    margin-bottom: 20px;
}

.not_found h4 {
    font-size: 40px;
    font-weight: 500;
}